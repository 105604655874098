import { style } from 'typestyle'
import { getColor } from '@/utils'

export const authPage = style({
  backgroundImage: 'url(/images/swashly.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  height: '100vh',
  display: 'flex',
})

export const authForm = style({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: getColor('white'),
  borderRadius: '10px',
  padding: '75px 20px',
  $nest: {
    h1: {
      maxWidth: '500px',
      color: getColor('swashly-dark-blue'),
    },
    button: {
      width: '100%',
      backgroundColor: getColor('swashly-dark-blue'),
      borderRadius: '20px',
      $nest: {
        '&:disabled': {
          backgroundColor: getColor('secondary-light-gray'),
        },
      },
    },
  },
})

export const authText = style({
  fontSize: '14px',
  lineHeight: '24px',
  color: getColor(),
  margin: '10px 0',
})

export const roleText = style({
  fontSize: '14px',
  lineHeight: '24px',
  color: getColor(),
  paddingTop: '24px',
  paddingBottom: '6px',
})

export const authPageLogo = style({
  display: 'flex',
  marginLeft: '30%',
})

export const checkInfoItem = (validated: boolean, valid: boolean): string =>
  style({
    fontSize: '14px',
    paddingLeft: '25px',
    marginBottom: '10px',
    lineHeight: '20px',
    fontWeight: '400',
    color: validated
      ? valid
        ? getColor('primary-green')
        : getColor('primary-red')
      : getColor(),
    background:
      validated && valid
        ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjguMzUiIGZpbGw9IiNmZmYiIHN0cm9rZT0iI0M0QzRDNCIgc3Ryb2tlLXdpZHRoPSIxLjMiLz48cGF0aCBkPSJNNi45ODcgMTIuMzdhLjc5Ny43OTcgMCAwIDEtLjU2Mi0uMjM0TDQuMjM0IDkuOTQ0QS43OTcuNzk3IDAgMSAxIDUuMzYgOC44MTZsMS42MyAxLjYzIDUuMjEzLTUuMjEyQS43OTcuNzk3IDAgMSAxIDEzLjMzIDYuMzZMNy41NTMgMTIuMTRhLjc5Ny43OTcgMCAwIDEtLjU2Ni4yM1oiIGZpbGw9IiM0Q0IwNTYiLz48L3N2Zz4=') left center no-repeat"
        : "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjguMzUiIGZpbGw9IiNmZmYiIHN0cm9rZT0iI0M0QzRDNCIgc3Ryb2tlLXdpZHRoPSIxLjMiLz48L3N2Zz4=') left center no-repeat",
  })

export const container = style({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: getColor('white'),
  borderRadius: '20px',
  padding: '96px 64px',
  width: '550px',
})

export const form = style({
  width: '100%',
})

export const topText = style({
  fontSize: '32px',
  fontWeight: 'bold',
  marginBottom: '48px',
  color: getColor('swashly-dark-blue'),
})

export const forgotPasswordTextStyle = {
  fontSize: '16px',
}

export const input = style({
  marginBottom: '40px',
})

export const buttonStyle = {
  backgroundColor: getColor('swashly-dark-blue'),
  fontSize: '20px',
  width: '100%',
  height: '58px',
}
