
import { computed, defineComponent, ref, watch } from 'vue'
import { useForm, FormActions } from 'vee-validate'
import { get, isEmpty } from 'lodash'
import { useRouter } from 'vue-router'
import AuthService from '@/graphqlBackOffice/auth/service'
import * as yup from 'yup'
import * as cssAuth from '../styles/Auth'
import * as cssRegister from '../styles/Register'
import { formError } from '@/components/ui/styles'
import { roles, projectRoles } from '@/utils/constants'
import { useAuthentication } from '@/composables/useAuthentication'

interface RegisterFormType {
  name: string
  email: string
  password: string
  passwordConfirm: string
  country: string
}

export default defineComponent({
  props: {
    token: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { currentRoute, push } = useRouter()
    const { loginUser } = useAuthentication()
    const isLoading = ref(false)
    const isFirstTime = ref(true)
    const isRegisterFailed = ref()
    const { registerUser } = AuthService.useMutations()
    const { result, loading } = AuthService.getEmailFromInvitation(props.token)
    const role = ref('')
    const passwordRules = ref({
      upperCase: false,
      length: false,
    })
    const minLengthError = 'Password must have at least 8 characters'
    const upperCaseError = 'Password must contain at least an upper case letter'
    const customValidationSchema = yup.object().shape({
      name: yup.string().required('This field is required.'),
      password: yup
        .string()
        .required('This field is required.')
        .test('minLength', minLengthError, (value) => {
          passwordRules.value.length = !!(value && value.length >= 8)
          return value ? value.length >= 8 : true
        })
        .test('upperCase', upperCaseError, (value) => {
          const valid = !!value?.match(/^(?=.*[A-Z]).*$/)
          passwordRules.value.upperCase = !!(value && valid)
          return value ? valid : true
        }),
      passwordConfirm: yup
        .string()
        .required('This field is required.')
        .oneOf([yup.ref('password')], 'Passwords must match'),
      country: yup.string().required('This field is required.'),
    })
    const { setValues, handleSubmit, meta, errors } = useForm({
      validationSchema: customValidationSchema,
    })

    const hasErrors = computed(() => !isEmpty(errors.value))

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          if (result.value?.getEmailFromInvitation) {
            role.value = result.value?.getEmailFromInvitation.role
            setValues({
              email: result.value?.getEmailFromInvitation.userEmail,
            })
          }
        }
      }
    )

    const checkTheFirstTime = () => {
      isFirstTime.value = false
    }

    const onSubmit = handleSubmit(
      async (values: RegisterFormType, { resetForm }: typeof FormActions) => {
        const redirectedFrom = get(currentRoute, 'value.redirectedFrom.path')
        const redirectTo = redirectedFrom !== '/logout' ? redirectedFrom : '/'

        isLoading.value = true
        try {
          const response = await registerUser.mutate({
            name: values.name,
            email: values.email,
            password: values.password,
            token: props.token,
            country: values.country,
          })
          resetForm()
          loginUser(response?.data.registerUser)
          isRegisterFailed.value = false
          push({ path: redirectTo || '/registration-successful' })
        } catch (e) {
          isRegisterFailed.value = true
          console.log(e)
        } finally {
          isLoading.value = false
        }
      }
    )

    return {
      cssRegister,
      cssAuth,
      role,
      roles: { ...roles, ...projectRoles },
      loading,
      onSubmit,
      isLoading,
      isRegisterFailed,
      formError,
      passwordRules,
      meta,
      hasErrors,
      isFirstTime,
      checkTheFirstTime,
    }
  },
})
