import { style } from 'typestyle'
import { loadingOverlay } from '@/components/ui/styles/BckLoading'
import { getColor } from '@/utils'

export const registerResponse = style({
  flex: 1,
  display: 'inline-grid',
  alignItems: 'center',
  justifyContent: 'center',
  $nest: {
    h1: {
      maxWidth: '500px',
      textAlign: 'center',
      color: getColor('primary-navy'),
      letterSpacing: '0.1em',
    },
    h5: {
      textAlign: 'center',
    },
    button: {
      margin: '0 auto',
    },
  },
})

export const registerResponseIcon = style({
  justifySelf: 'center',
})

export const registerResponseIconFail = style({
  justifySelf: 'center',
  marginTop: '-25px',
})

export const registerPageContent = (isLoading: boolean): string =>
  style({
    height: '100%',
    ...(isLoading && loadingOverlay),
  })

export const registerInput = style({
  marginBottom: '18px !important',
})

export const countryInput = style({
  marginTop: '-20px !important',
})

export const passwordInput = style({
  marginBottom: '0px !important',
})

export const registerEmailInput = style({
  marginTop: '3px !important',
})

export const registerAuthForm = style({
  width: '552px',
  borderRadius: '20px',
  padding: '44px 61px 30px 61px !important',
  $nest: {
    h1: {
      margin: 0,
    },
  },
})

export const registerSuccessful = (isExpired?: boolean): string =>
  style({
    backgroundColor: getColor('white'),
    borderRadius: '20px',
    backdropFilter: 'blur(13.5px)',
    width: '625px',
    height: '627px',
    padding: isExpired ? '70px 97px 73px 97px' : '100px 97px 73px 97px',
  })

export const successTitle = style({
  color: '#000B30',
  textAlign: 'center',
  fontSize: '40px',
  fontStyle: 'normal',
  lineHeight: '140%',
  marginTop: '30px',
  marginBottom: '50px',
})

export const successText = (isExpired?: boolean): string =>
  style({
    color: '#000B30',
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
    marginBottom: isExpired ? '44px' : '72px',
  })

export const registerTitle = style({
  color: '#06103A',
  fontSize: '24px',
  fontStyle: 'normal',
  lineHeight: '140%',
  paddingBottom: '12px',
})

export const formWrapper = style({
  paddingLeft: '26px',
})

export const form = style({
  width: '100%',
})
