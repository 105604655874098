import { createSession } from '@/graphqlBackOffice/auth/session'
import { useStore } from '@/store'

interface LoginResponse {
  token: string
  refreshToken: string
  user: any
}

interface UseAuthenticationComposable {
  loginUser: (loginResponse: LoginResponse) => void
}

export const useAuthentication = (): UseAuthenticationComposable => {
  const store = useStore()

  const loginUser = (loginResponse: LoginResponse): void => {
    store.dispatch('authModule/setIsLoggedIn', true)
    createSession(
      loginResponse.token,
      loginResponse.refreshToken,
      loginResponse.user
    )
  }

  return { loginUser }
}
