
import { defineComponent, ref } from 'vue'
import { useForm } from 'vee-validate'
import AuthService from '@/graphqlBackOffice/auth/service'
import * as yup from 'yup'
import * as css from '../styles/Auth'
import { formError } from '@/components/ui/styles'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    linkStatus: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { forgotPassword } = AuthService.useMutations()
    const isLoading = ref(false)
    const sent = ref(false)
    const error = ref('')
    const email = ref('')
    const { push } = useRouter()
    const customValidationSchema = yup.object().shape({
      email: yup
        .string()
        .email('Invalid email')
        .required('This field is required.'),
    })
    const { handleSubmit, meta, values } = useForm({
      validationSchema: customValidationSchema,
    })

    const onSubmit = handleSubmit(async () => {
      error.value = ''
      isLoading.value = true
      const { mutate, error: mutationError } = forgotPassword
      try {
        email.value = values.email
        await mutate({ email: values.email })
        sent.value = true
      } catch (e) {
        const { networkErrorMessage } = useErrors(mutationError)
        error.value =
          networkErrorMessage.value ||
          'Something went wrong, please try again later'
      } finally {
        isLoading.value = false
      }
    })

    return {
      css,
      email,
      error,
      isLoading,
      meta,
      onSubmit,
      formError,
      push,
      sent,
    }
  },
})
