
import { defineComponent } from 'vue'
import { getSessionValue } from '@/graphqlBackOffice/auth/session'
import * as cssAuth from '../styles/Auth'
import * as cssRegister from '../styles/Register'

export default defineComponent({
  setup() {
    const userName = getSessionValue('user').username

    return {
      cssRegister,
      cssAuth,
      userName,
    }
  },
})
