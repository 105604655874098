
import { defineComponent, ref, watch } from 'vue'
import { useForm } from 'vee-validate'
import AuthService from '@/graphqlBackOffice/auth/service'
import * as yup from 'yup'
import * as css from '../styles/Auth'
import {
  formError,
  formErrorSpacingPlaceholder,
  margin,
} from '@/components/ui/styles'
import { roles, projectRoles } from '@/utils/constants'
import { useRoute, useRouter } from 'vue-router'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'

interface RestPasswordFormType {
  newPassword: string
  newPasswordConfirm: string
}

export default defineComponent({
  setup() {
    const minLengthError = 'Password must have at least 8 characters'
    const upperCaseError = 'Password must contain at least an upper case letter'
    const submitting = ref(false)
    const invalidToken = ref(false)
    const error = ref('')
    const passwordChanged = ref(false)
    const email = ref('')
    const { query } = useRoute()
    const { push } = useRouter()

    if (!query.token || !query._id) {
      push({ name: 'Forgot Password' })
    }

    const passwordRules = ref({
      upperCase: false,
      length: false,
    })

    const {
      result,
      loading: loadingEmail,
      error: errorMessage,
    } = AuthService.getEmailFromRecoveryHash(
      query.token as string,
      query._id as string
    )
    const { resetPassword } = AuthService.useMutations()

    watch(
      () => loadingEmail.value,
      (loading) => {
        if (!loading) {
          if (result.value?.getEmailFromRecoveryHash) {
            email.value = result.value?.getEmailFromRecoveryHash.email
          } else {
            const error = useErrors(errorMessage ?? ref(null))
              .networkErrorMessage.value
            console.log(error)
            if (error === 'Invalid recovery hash') {
              push({
                name: 'Forgot Password',
                params: { linkStatus: 'expired' },
              })
            } else {
              invalidToken.value = true
            }
          }
        }
      }
    )

    const { handleSubmit, errors, meta } = useForm({
      validationSchema: yup.object().shape({
        newPassword: yup
          .string()
          .required('This field is required.')
          .test('minLength', minLengthError, (value) => {
            passwordRules.value.length = !!(value && value.length >= 8)
            if (!value) {
              return true
            }

            return value.length >= 8
          })
          .test('upperCase', upperCaseError, (value) => {
            const valid = !!value?.match(/^(?=.*[A-Z]).*$/)
            passwordRules.value.upperCase = !!(value && valid)
            if (!value) {
              return true
            }

            return valid
          }),
        newPasswordConfirm: yup
          .string()
          .required('This field is required.')
          .oneOf([yup.ref('newPassword')], 'Passwords must match'),
      }),
    })

    const onSubmit = handleSubmit(async (values: RestPasswordFormType) => {
      submitting.value = true
      try {
        await resetPassword.mutate({
          email: email.value,
          password: values.newPassword,
          hash: query.token,
        })

        passwordChanged.value = true
      } catch (e) {
        error.value = 'Something went wrong, please try again later'
      } finally {
        submitting.value = false
      }
    })

    return {
      css: {
        ...css,
        formError,
        formErrorSpacingPlaceholder,
        margin,
      },
      error,
      errors,
      email: query.email,
      invalidToken,
      loadingEmail,
      meta,
      roles: { ...roles, ...projectRoles },
      onSubmit,
      passwordChanged,
      passwordRules,
      push,
      submitting,
    }
  },
})
