
import { defineComponent, ref } from 'vue'
import { useForm, FormActions } from 'vee-validate'
import { get } from 'lodash'
import { useRouter } from 'vue-router'
import AuthService from '@/graphqlBackOffice/auth/service'
import * as yup from 'yup'
import * as css from '../styles/Auth'
import { formError } from '@/components/ui/styles'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'
import { useAuthentication } from '@/composables/useAuthentication'

interface LoginFormType {
  email: string
  password: string
}

export default defineComponent({
  setup() {
    const { currentRoute, push } = useRouter()
    const { loginUser } = useAuthentication()
    const isLoading = ref(false)
    const loginError = ref('')

    const customValidationSchema = yup.object().shape({
      email: yup.string().required('This field is required.'),
      password: yup.string().required('This field is required.'),
    })

    const { handleSubmit } = useForm({
      validationSchema: customValidationSchema,
    })

    const onSubmit = handleSubmit(
      (values: LoginFormType, { resetForm }: typeof FormActions) => {
        loginError.value = ''
        const redirectedFrom = get(currentRoute, 'value.redirectedFrom.path')
        const redirectTo = redirectedFrom !== '/logout' ? redirectedFrom : '/'
        const { login, loading, error } = AuthService.logIn(
          values.email,
          values.password
        )

        isLoading.value = loading.value
        login
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((response: any) => {
            resetForm()
            loginUser(response.data.login)
          })
          .catch(() => {
            const { networkErrorMessage } = useErrors(error)
            loginError.value =
              networkErrorMessage.value ||
              'Something went wrong, please try again later'
          })
          .finally(() => {
            isLoading.value = loading.value
            push({ path: redirectTo || '/' })
          })
      }
    )

    return {
      css,
      onSubmit,
      isLoading,
      formError,
      loginError,
      push,
    }
  },
})
